// const BASE_URL = "http://192.168.10.40:5013/";
// const BASE_URL = "https://api.wappss.com/";
// const BASE_URL = "https://api.wappss.com/";
// const BASE_URL = "https://api.infinitemlmsoftware.com/";


// const BASE_URL = "http://162.19.146.135:1538/";
const DEFAULT_KEY = '8be61683-b0a2-47da-a81d-fd5a7a5227bb'

const BASE_URL = "http://admin.420kings.net/node_api/";

const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }
