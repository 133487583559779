import React, { useState, useRef, useEffect } from "react";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";
import Loader from "react-js-loader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";


const CompensationPlan = () => {
  const { t } = useTranslation();
  const items = ApiHook.CallCompensationPlan();
  const markAsCompleted = ApiHook.CallCompletedCompensationPlan();
  const [isCompleted, setIsCompleted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef(null);
  const navigate = useNavigate();

  const getYouTubeId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  const loaderStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    zIndex: 1000,
  };

  const onEnd = () => {
    setIsCompleted(true);
    setIsPlaying(false);
    console.log("Video playback completed");
    markAsCompleted.mutate(null, {
        onSuccess: (response) => {
            console.log({response})
          if (response.data && response.data.status) {
            toast.success(t("statusSuccess"));
            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else {
            toast.error(t("Some Error Occured"));
          }
        },
        onError: (error) => {
            console.log({error})
          toast.error(t("Some Error Occured")|| "Failed to mark video as completed.");
        }
      });
  };

  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 0,
      disablekb: 1,
      rel: 0,
      controls: 0,
      modestbranding: 1,
      showinfo: 0,
      fs: 0,
      playsinline: 1,
      enablejsapi: 1,
      iv_load_policy: 3,
      loop: 0,
      origin: window.location.origin,
    },
  };

  const onReady = (event) => {
    playerRef.current = event.target;
  };

  const togglePlayPause = () => {
    if (playerRef.current) {
      if (isPlaying) {
        playerRef.current.pauseVideo();
      } else {
        playerRef.current.playVideo();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    let lastTime = -1;
    let intervalId;

    const checkTime = () => {
      if (playerRef.current && playerRef.current.getCurrentTime) {
        const currentTime = playerRef.current.getCurrentTime();
        if (currentTime > lastTime + 1 || currentTime < lastTime) {
          playerRef.current.seekTo(lastTime);
        }
        lastTime = currentTime;
      }
    };

    if (playerRef.current) {
      intervalId = setInterval(checkTime, 100);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  if (items.isLoading || markAsCompleted.isLoading) {
    return (
      <div style={loaderStyle}>
        <Loader
          type="bubble-scale"
          bgColor={"#954cea"}
          color={"#ffffff"}
          size={50}
        />
      </div>
    );
  }

  return (
    <div
    >
      <div className="page_head_top">{t("compensationPlan")}</div>
      {items && items.data && items.data.status === false && (
        <h6>{t(items.data.data.description) ?? "Error"}</h6>
      )}
      {items && items.data && items.data.url && (
        <div style={{ position: "relative",display:"flex",justifyContent:"center",width:"100%" }}>
          <YouTube
            videoId={getYouTubeId(items.data.url)}
            opts={opts}
            onReady={onReady}
            onEnd={onEnd}
          />
          <div
            onClick={togglePlayPause}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              cursor: "pointer",
            }}
          />
          {isCompleted && <p>Video playback completed!</p>}
        </div>
      )}
    </div>
  );
};

export default CompensationPlan;
