import React from "react";
import CrmLinks from "../../components/Crm/CrmLinks";
import CrmViewLead from "../../components/Crm/CrmViewLead";

const ViewLead = () => {

  return (
    <>
      <CrmLinks />
      <CrmViewLead />
    </>
  );
};

export default ViewLead;
